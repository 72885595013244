import { ENUMS } from '../models/constants';
import * as envUR from './universal-realty';
import * as envHH from './house-hub';
import * as envHHdemo from './hhdemo';
import * as envProRealty from './pro-realty';

let isLocalhost = false;
let envType: any = ENUMS.PRO_REALTY_SITE;

let envir: any;
if (envType === ENUMS.HOUSEHUB_SITE) {
    envir = envHH.environment;
} else if (envType === ENUMS.DEMO_SITE) {
    envir = envHHdemo.environment;
} else if (envType === ENUMS.UNIVERSAL_REALTY_SITE) {
    envir = envUR.environment;
} else if (envType === ENUMS.PRO_REALTY_SITE) {
    envir = envProRealty.environment;
} else {
    envir = envHH.environment;
}

envir['envType'] = envType;
envir['isLocalhost'] = isLocalhost;
envir['domainName'] = 'ProrealtyDomainGroup';  //This is the s3 folder name(to lower case) in Blogs bucket 
envir['domainNameRaw'] = 'https://www.prorealtync.com';  //This is the s3 folder name(to lower case) in Blogs bucket 

export const environment = envir;
