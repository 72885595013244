export const communityData = [
    {
        city: 'Cary',
        city_zh_CN: '卡瑞',
        city_zh_HK: '卡瑞',
        sections: [
            {
                title: 'Perfect Mix of Southern Charm and Modern Convenience',
                description: "Cary offers a plethora of amenities and opportunities for residents and visitors alike. Strategically positioned within the Triangle area, Cary enjoys easy access to major cities such as Raleigh, Durham, and Chapel Hill. Its close proximity to the Raleigh-Durham International Airport further enhances its connectivity. Cary boasts a robust and diversified business community, including major corporations, tech startups, and small businesses. Many top schools within the Wake County Public School System are located in Cary as well as private schools such as Cary Christian School, Grace Christian, and Cary Academy. Cary takes pride in its extensive park system, which encompasses over 30 parks, greenways, and recreational facilities. Residents and visitors can enjoy a wide range of outdoor activities, including hiking, biking, picnicking, and sports.",
                title_zh_CN: '南方魅力与现代便利的完美结合',
                description_zh_CN: "卡里为居民和游客提供了大量的便利设施和机会。卡里地理位置优越，位于三角地区，可轻松前往罗利、达勒姆和教堂山等主要城市。它毗邻罗利达勒姆国际机场，进一步增强了其连通性。卡里拥有强大且多元化的商业社区，包括大公司、科技初创公司和小型企业。威克县公立学校系统内的许多顶尖学校都位于卡里，还有卡里基督教学校、格雷斯基督教学校和卡里学院等私立学校。卡里以其广泛的公园系统而自豪，其中包括 30 多个公园、绿道和娱乐设施。居民和游客可以享受各种户外活动，包括徒步旅行、骑自行车、野餐和运动。",
                title_zh_HK: '南方魅力與現代便利的完美結合',
                description_zh_HK: "卡里為居民和遊客提供了大量的便利設施和機會。卡里地理位置優越，位於三角地區，可輕鬆前往羅利、達勒姆和教堂山等主要城市。它毗鄰羅利達勒姆國際機場，進一步增強了其連通性。卡里擁有強大且多元化的商業社區，包括大公司、科技新創公司和小型企業。威克縣公立學校系統內的許多頂尖學校都位於卡里，還有卡里基督教學校、格雷斯基督教學校和卡里學院等私立學校。卡里以其廣泛的公園系統而自豪，其中包括 30 多個公園、綠道和娛樂設施。居民和遊客可以享受各種戶外活動，包括健行、騎自行車、野餐和運動。"
            }
        ],
    },
    {
        city: 'Morrisville',
        city_zh_CN: '莫里斯维尔',
        city_zh_HK: '莫里斯维尔',
        sections: [
            {
                title: 'Strategic location with Superb Quality of Life',
                description: "Morrisville's prime location within the Research Triangle Park (RTP) region positions it as a hub for innovation and economic growth. Its proximity to major cities like Raleigh, Durham, and Chapel Hill facilitates easy access to key business centers, educational institutions, and research facilities. With access to top-tier educational institutions such as North Carolina State University, Duke University, and the University of North Carolina at Chapel Hill, Morrisville offers exceptional opportunities for professional development and collaboration within academia and industry. Morrisville is renowned for its exceptional quality of life, supported by well-planned neighborhoods, top-notch schools, and abundant recreational amenities.",
                title_zh_CN: '地理位置优越，生活品质一流',
                description_zh_CN: "Apex 始終被評為美國最適合居住的地方之一，完美融合了小鎮魅力和現代便利設施。大自然愛好者一定會喜歡 Apex 豐富的公園、綠道和娛樂設施。漫步穿過 Apex 迷人的歷史悠久的市中心區，這裡遍布古色古香的商店、精品店、咖啡館和餐廳。 Apex 地理位置優越，位於北卡羅來納州中心地帶，可輕鬆前往 I-540 和 US-1 等主要高速公路，方便前往羅利、達勒姆和教堂山等鄰近城市。",
                title_zh_HK: '地理位置優越，生活品質一流',
                description_zh_HK: "莫里斯維爾在三角研究園 (RTP) 地區的黃金地段使其成為創新和經濟成長的中心。它毗鄰羅利、達勒姆和教堂山等主要城市，方便前往主要商業中心、教育機構和研究設施。莫里斯維爾擁有北卡羅來納州立大學、杜克大學和北卡羅來納大學教堂山分校等頂級教育機構的入學機會，為學術界和工業界的專業發展和合作提供了絕佳的機會。莫里斯維爾以其卓越的生活品質而聞名，擁有精心規劃的社區、一流的學校和豐富的娛樂設施。"
            }
        ]
    },
    {
        city: 'Apex',
        city_zh_CN: '埃佩克斯',
        city_zh_HK: '埃佩克斯',
        sections: [
            {
                title: 'Quality of Life with Strong Sense of Community',
                description: "Apex consistently ranks as one of the best places to live in the United States, offering a perfect blend of small-town charm and modern conveniences. Nature enthusiasts will love Apex's abundance of parks, greenways, and recreational facilities. Take a stroll through Apex's charming historic downtown district, lined with quaint shops, boutiques, cafes, and restaurants. Strategically situated in the heart of North Carolina, Apex offers easy access to major highways, including I-540 and US-1, facilitating convenient travel to neighboring cities such as Raleigh, Durham, and Chapel Hill.",
                title_zh_CN: '具有強烈社區意識的生活品質',
                description_zh_CN: "Apex 始終被評為美國最適合居住的地方之一，完美融合了小鎮魅力和現代便利設施。大自然愛好者一定會喜歡 Apex 豐富的公園、綠道和娛樂設施。漫步穿過 Apex 迷人的歷史悠久的市中心區，這裡遍布古色古香的商店、精品店、咖啡館和餐廳。 Apex 地理位置優越，位於北卡羅來納州中心地帶，可輕鬆前往 I-540 和 US-1 等主要高速公路，方便前往羅利、達勒姆和教堂山等鄰近城市。",
                title_zh_HK: '具有強烈社區意識的生活品質',
                description_zh_HK: "Apex 始終被評為美國最適合居住的地方之一，完美融合了小鎮魅力和現代便利設施。大自然愛好者一定會喜歡 Apex 豐富的公園、綠道和娛樂設施。漫步穿過 Apex 迷人的歷史悠久的市中心區，這裡遍布古色古香的商店、精品店、咖啡館和餐廳。 Apex 地理位置優越，位於北卡羅來納州中心地帶，可輕鬆前往 I-540 和 US-1 等主要高速公路，方便前往羅利、達勒姆和教堂山等鄰近城市。"
            }
        ]
    },
    {
        city: 'Chapel Hill',
        city_zh_CN: '查珀尔希尔',
        city_zh_HK: '查珀尔希尔',
        sections: [
            {
                title: 'Academic Excellence, Cultural Vibrancy, and Picturesque Surroundings',
                description: "Home to the prestigious University of North Carolina at Chapel Hill (UNC), Chapel Hill is steeped in academic excellence. Chapel Hill boasts a rich cultural scene, with a thriving arts community, renowned music venues, art galleries, and theaters. Explore Chapel Hill's historic downtown, characterized by tree-lined streets, historic buildings, and charming boutiques, cafes, and restaurants.",
                title_zh_CN: '卓越的学术成就、文化活力和风景如画的环境',
                description_zh_CN: "教堂山是著名的北卡羅來納大學教堂山分校 (UNC) 的所在地，學術成就卓著。教堂山擁有豐富的文化景觀，擁有蓬勃發展的藝術社區、著名的音樂場館、美術館和劇院。探索教堂山歷史悠久的市中心，這裡有綠樹成蔭的街道、歷史悠久的建築以及迷人的精品店、咖啡館和餐廳。",
                title_zh_HK: '卓越的學術成就、文化活力和風景如畫的環境',
                description_zh_HK: "教堂山是著名的北卡羅來納大學教堂山分校 (UNC) 的所在地，學術成就卓著。教堂山擁有豐富的文化景觀，擁有蓬勃發展的藝術社區、著名的音樂場館、美術館和劇院。探索教堂山歷史悠久的市中心，這裡有綠樹成蔭的街道、歷史悠久的建築以及迷人的精品店、咖啡館和餐廳。"
            }
        ]
    },
    {
        city: 'Durham',
        city_zh_CN: '达勒姆',
        city_zh_HK: '达勒姆',
        sections: [
            {
                title: 'Diverse Community with a Rich History and Vibrant Culture',
                description: "Durham is renowned for its exceptional healthcare facilities, including Duke University Hospital and the Duke Health system. With its diverse population and vibrant cultural scene, Durham offers a rich tapestry of arts, music, and cuisine. Discover Durham's rich history through its historic neighborhoods, iconic landmarks, and revitalized downtown district. Durham is a haven for artists, musicians, and performers, with numerous theaters, galleries, and cultural institutions showcasing local talent and internationally acclaimed acts.",
                title_zh_CN: '拥有丰富历史和充满活力文化的多元化社区',
                description_zh_CN: "达勒姆以其卓越的医疗设施而闻名，包括杜克大学医院和杜克医疗系统。达勒姆拥有多元化的人口和充满活力的文化景观，提供丰富的艺术、音乐和美食。通过历史街区、标志性地标和复兴的市中心区，探索达勒姆丰富的历史。达勒姆是艺术家、音乐家和表演者的天堂，拥有众多剧院、画廊和文化机构，展示当地人才和国际知名的表演。",
                title_zh_HK: '擁有豐富歷史和充滿活力文化的多元化社區',
                description_zh_HK: "達勒姆以其卓越的醫療設施而聞名，包括杜克大學醫院和杜克醫療系統。達勒姆擁有多元化的人口和充滿活力的文化景觀，提供豐富的藝術、音樂和美食。透過歷史街區、標誌性地標和復興的市中心區，探索達勒姆豐富的歷史。達勒姆是藝術家、音樂家和表演者的天堂，擁有許多劇院、畫廊和文化機構，展示當地人才和國際知名的表演。"
            }
        ]
    },
    {
        city: 'Raleigh',
        city_zh_CN: '罗利',
        city_zh_HK: '罗利',
        sections: [
            {
                title: "'City of Oaks' is a Dynamic and Thriving Metropolitan Area",
                description: "Raleigh serves as the capital city of North Carolina and anchors the renowned Research Triangle Park (RTP), one of the largest research and technology parks in the world. Raleigh is home to several prestigious universities and colleges, including North Carolina State University (NCSU), Meredith College, and William Peace University. Raleigh boasts a rich cultural scene with a plethora of museums, art galleries, theaters, and cultural festivals celebrating the city's diverse heritage. Raleigh's downtown core is experiencing a renaissance, characterized by urban revitalization, mixed-use development, and a burgeoning culinary scene.",
                title_zh_CN: "'橡树之城'是一个充满活力和繁荣的大都市区",
                description_zh_CN: "罗利是北卡罗来纳州的首府，拥有著名的三角研究园 (RTP)，这是世界上最大的研究和技术园区之一。罗利拥有多所著名大学和学院，包括北卡罗来纳州立大学 (NCSU)、梅雷迪思学院和威廉和平大学。罗利拥有丰富的文化景观，拥有众多博物馆、艺术画廊、剧院和庆祝城市多元化遗产的文化节。罗利市中心正在经历一场复兴，其特点是城市复兴、多功能开发和蓬勃发展的烹饪场所。",
                title_zh_HK: '「橡樹之城」是一個充滿活力和繁榮的大都會區',
                description_zh_HK: "羅利是北卡羅來納州的首府，擁有著名的三角研究園區 (RTP)，這是世界上最大的研究和技術園區之一。羅利擁有多所著名大學和學院，包括北卡羅來納州立大學 (NCSU)、梅雷迪思學院和威廉和平大學。羅利擁有豐富的文化景觀，擁有許多博物館、藝廊、劇院和慶祝城市多元化遺產的文化節。羅利市中心正在經歷一場復興，其特點是城市復興、多功能開發和蓬勃發展的烹飪場所。"
            }
        ]
    },
    {
        city: 'Holly Springs',
        city_zh_CN: '霍利斯普林斯',
        city_zh_HK: '霍利斯普林斯',
        sections: [
            {
                title: "Charming and Rapidly Growing Town",
                description: "Holly Springs maintains its small-town charm and sense of community despite its rapid growth. Situated in the Research Triangle region, Holly Springs enjoys easy access to major transportation routes, including I-540 and US-1, facilitating convenient travel to neighboring cities such as Raleigh, Durham, and Chapel Hill. Raleigh-Durham International Airport (RDU) is also within a short driving distance. Outdoor enthusiasts will appreciate Holly Springs' abundance of parks, greenways, and recreational facilities.",
                title_zh_CN: "迷人且快速发展的小镇",
                description_zh_CN: "尽管发展迅速，霍利斯普林斯仍保持着小镇的魅力和社区意识。 Holly Springs 位于研究三角地区，可轻松前往主要交通路线，包括 I-540 和 US-1，方便前往罗利、达勒姆和教堂山等邻近城市。罗利达勒姆国际机场 (RDU) 距酒店也只有很短的车程。户外运动爱好者一定会欣赏霍利斯普林斯丰富的公园、绿道和娱乐设施。",
                title_zh_HK: '迷人且快速發展的小鎮',
                description_zh_HK: "儘管發展迅速，霍利斯普林斯仍保持著小鎮的魅力和社區意識。 Holly Springs 位於研究三角地區，可輕鬆前往主要交通路線，包括 I-540 和 US-1，方便前往羅利、達勒姆和教堂山等鄰近城市。羅利達勒姆國際機場 (RDU) 也只需短暫的車程即可抵達飯店。戶外運動愛好者一定會欣賞霍利斯普林斯豐富的公園、綠道和娛樂設施。"
            }
        ]
    },
    {
        city: 'Fuquay Varina',
        city_zh_CN: '富基瓦里纳',
        city_zh_HK: '富基瓦里纳',
        sections: [
            {
                title: "Charming and Rapidly Growing Town",
                description: "Holly Springs maintains its small-town charm and sense of community despite its rapid growth. Situated in the Research Triangle region, Holly Springs enjoys easy access to major transportation routes, including I-540 and US-1, facilitating convenient travel to neighboring cities such as Raleigh, Durham, and Chapel Hill. Raleigh-Durham International Airport (RDU) is also within a short driving distance. Outdoor enthusiasts will appreciate Holly Springs' abundance of parks, greenways, and recreational facilities.",
                title_zh_CN: "迷人且快速发展的小镇",
                description_zh_CN: "尽管发展迅速，霍利斯普林斯仍保持着小镇的魅力和社区意识。 Holly Springs 位于研究三角地区，可轻松前往主要交通路线，包括 I-540 和 US-1，方便前往罗利、达勒姆和教堂山等邻近城市。罗利达勒姆国际机场 (RDU) 距酒店也只有很短的车程。户外运动爱好者一定会欣赏霍利斯普林斯丰富的公园、绿道和娱乐设施。",
                title_zh_HK: '迷人且快速發展的小鎮',
                description_zh_HK: "儘管發展迅速，霍利斯普林斯仍保持著小鎮的魅力和社區意識。 Holly Springs 位於研究三角地區，可輕鬆前往主要交通路線，包括 I-540 和 US-1，方便前往羅利、達勒姆和教堂山等鄰近城市。羅利達勒姆國際機場 (RDU) 也只需短暫的車程即可抵達飯店。戶外運動愛好者一定會欣賞霍利斯普林斯豐富的公園、綠道和娛樂設施。"
            }
        ]
    }

]