import { Injectable, inject, HostListener, OnInit } from '@angular/core';
import { CommonModule, DOCUMENT, ViewportScroller } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { md5 } from 'js-md5';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { AuthService } from '../services/auth.service';
import { environment } from '../../env/environment';


@Injectable({
  providedIn: 'root'
})
export class AppServiceService implements OnInit {

  environment = environment;
  isMobile: boolean = false;
  public subject = new Subject<any>();
  deviceInfo: any;
  session: any;

  urlWorkOrderFileUpload = `${environment.rootUrl}prod/workOrder/upload`;

  sessionEndTime: any;
  minutesToTimeout = 30;

  urlSelectedVendor = null;
  urlSelectedWoId = null;
  urlSelectedAddress = null;

  isTenant = [
    'True',
    'False'
  ]

  interactions = [
    'Seen',
    'Not Seen',
  ]

  distributionTypes = [
    '<Test Email>',
    'All Confirmed', // domain + confirmed subscribers
    'All Tenants',   // domain + confirmed + isTenant
    'By Community',  // domain + confirmed + community + isTenant
    'By Interaction', // domain + confirmed + interaction + isTenant
  ]

  communities = [
    'Amberly-Overlook',
    'Amberly-Glen',
    'Breckenridge',
    'Breckenridge-ManorsI',
    'Breckenridge-ManorsII',
    'Collin Grove',
    'Crossings',
    'Haddon Hall',
    'Hempstead',
    'Harmony',
    'Manchester',
    'McCrimmon at the Park',
    'Oak',
    'Savanna',
    'Town at North Salem',
    'Village of Apex',
    'Wellsley',
    'All',
  ]

  private readonly document = inject(DOCUMENT);
  private readonly viewport = inject(ViewportScroller);

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Access-Control-Allow-Methods', 'OPTIONS,POST')
    // .set("Access-Control-Allow-Headers", 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token')
    // .set('Authorization', `Bearer ${environment.mlsBearToken}`)
    .set('Content-Type', `application/json`);

  constructor(
    private deviceService: DeviceDetectorService,
    private httpClient: HttpClient,
    // public authService: AuthService,
  ) {
    this.checkMobile();

    if (!this.sessionEndTime) {
      this.sessionEndTime = new Date((new Date()).getTime() + 30 * 60 * 1000);  // initialize end time, but will be overwritten after login by setSessionTimeout
    }
  }

  async ngOnInit() {
    this.checkMobile();
    // this.session = await this.authService.getCurrentSession();
  }

  checkMobile() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  scrollToTop() {
    this.viewport.scrollToPosition([0, -500]);
  }

  sendData(data: any) {
    this.subject.next(data);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  sortLogic(a: any, b: any, field: any, type: string, direction: string) {
    if (type === 'string') {
      if (a[field].toLowerCase() < b[field].toLowerCase()) {
        return direction === 'asc' ? -1 : 1;
      } else if (a[field].toLowerCase() > b[field].toLowerCase()) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    } else {
      return direction === 'asc' ? a[field] - b[field] : b[field] - a[field];
    }
  }

  customSortA(field: any, type: string, direction: string) {
    let that = this;
    return function (a: any, b: any) {
      return that.sortLogic(a, b, field, type, direction);
    };
  }

  customSortB(a: any, b: any, field: any, type: string, direction: string) {
    return this.sortLogic(a, b, field, type, direction);
  }

  // Function to encode file to Base64
  encodeFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  showPhoneNumber(phoneObj: any) {
    if (phoneObj) {
      return `${phoneObj.area}-${phoneObj.exchange}-${phoneObj.subscriber}`;
    }
    return '';
  }

  // // Step 1: Get Pre-Signed URL from Lambda
  // getUploadUrl(fileName: string, fileType: string): Observable<any> {
  //   this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
  //   return this.httpClient.get(`${this.urlWorkOrderFileUpload}?fileName=${fileName}&fileType=${fileType}`);
  // }

  // // Step 2: Upload file to S3 using the pre-signed URL
  // uploadFileToS3(file: File, signedUrl: string): Observable<any> {
  //   return this.httpClient.put(signedUrl, file, {
  //     headers: {
  //       'Content-Type': file.type
  //     },
  //     reportProgress: true,
  //     observe: 'events'
  //   });
  // }

}
