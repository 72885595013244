import { ENUMS } from '../models/constants';

export const environment = {
    hostname: 'https://d1tx00hc5dhoug.cloudfront.net',

    // domainName: 'UniversalRealtyDomainGroup', // in ClientDomains table
    // From ClientDomains table, which maps domain URL to domain name. Cognito assign domain name to user. pro-realty.ts specify localhostdomainname for local testing. This get overwritten for prod by environment.prod_universal_realty.ts.
    domainName: 'LocalhostDomainGroup', //will be overwritten in environemnt.prod_universal_realty.ts

    clientName: 'Universal Realty',
    clientAddress: '5301 Denise Drive, Raleigh, NC 27606',
    clientPhone: '(919) 342-6183',
    clientEmail: 'universalrealty.info@gmail.com',

    enableGoogleMaps: true,
    googleMapsAPIKey: 'AIzaSyCSZRWstzI91T1zenoS1qEbdg4BtwO2bvg',
    googleMaps_Lat: 35.76572350077043,
    googleMaps_Lng: -78.72859163097104,

    reCaptchaSiteKey: '6Le-Z9EpAAAAAA4jiil_FxeX4u2xzbq5UUHmYRGu',

    // 'transition-background': only background and text change
    // 'drop-down': drop down and change background
    header: {
        type: ENUMS.HEADER_DROP_DOWN,
        // type: ENUMS.HEADER_TRANSITION_BACKGROUND,
    },

    facebook: 'www.facebook.com',
    instagram: 'www.instagram.com',
    twitter: 'www.twitter.com',
    linkedin: 'www.linkedin.com',
    pinterest: 'www.pinterest.com',

    rootUrl: 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/',
    contactUsUrl: 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/prod/contactUs',

    mlsR: 'MLS®',
    tmlsIdxImg: 'https://res.cloudinary.com/luxuryp/images/f_auto,q_auto/oj9yale6bkijvgworii0/screenshot_2024-01-13_at_113031_am-removebg-preview',

    // TODO: create separate bucket per client
    // bucketname: 'househubworkorder',
}
