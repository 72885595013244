import { Injectable, NgZone, OnInit, OnDestroy } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Router } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
// import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { AuthUser, getCurrentUser, signOut, fetchAuthSession, AuthTokens, updateUserAttributes, confirmUserAttribute, type ConfirmUserAttributeInput, updatePassword } from 'aws-amplify/auth';
import { AppServiceService } from '../services/app-service.service';

import { interval, Subject, lastValueFrom, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {

  url: any;
  ENUMS = ENUMS;
  environment = environment;

  hhSpecific = [
    '/pricing',
    '/terms-of-service',
  ]

  private currentUser: any | null = null;
  private userFullName: any;
  private intervalId: any;
  private intervalCheckTokenExpSubscription$: Subscription = new Subscription();

  isLandlordGroup = false;
  isTenantGroup = false;

  lastCheckedTokenDate: any;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    .set('Content-Type', `application/json`);

  constructor(
    private router: Router,
    public appService: AppServiceService,
    private ngZone: NgZone,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private location: Location,
  ) {
    this.url = this.router.url;

    this.router.events.subscribe((event: any) => {
      if (event && event.routeConfig && event.routeConfig.path) {
        this.url = event.routeConfig.path;
      }

      if (event && event.url) {
        this.url = event.url;
      }
    })

    setTimeout(() => {
      this.checkGroups();
    }, 1000)

    // setInterval(() => {
    //   this.checkTokenExpired();
    // }, 1000 * 60)
  }

  async ngOnInit() {
    // Run the interval outside of Angular's zone
    // this.ngZone.runOutsideAngular(() => {
    //   this.intervalId = setInterval(() => {
    //     // Do something here, Angular will not trigger change detection
    //     console.log('Interval running outside Angular zone');

    //     // If needed, re-enter Angular's zone to update the UI
    //     this.ngZone.run(() => {
    //       console.log('Re-entering Angular zone');
    //       this.checkTokenExpired();
    //     });
    //   }, 1000 * 3);
    // });

    console.log("auth server ngOnInit")

  }

  ngOnDestroy(): void {
    // if (this.intervalId) {
    //   clearInterval(this.intervalId);
    // }

    if (this.intervalCheckTokenExpSubscription$) {
      this.intervalCheckTokenExpSubscription$.unsubscribe();
    }
  }

  // 1/3 methods that use interval to check session end(interval freeze app)
  shouldCheckToken(): boolean {
    let currentDate: any = new Date();
    if ((currentDate - this.lastCheckedTokenDate) > 30e3) {
      this.lastCheckedTokenDate = currentDate;
      return true;
    } else {
      return false;
    }
  }
  // 2/3 methods that use interval to check session end(interval freeze app)
  setupCheckTokenExpInterval() {
    this.intervalCheckTokenExpSubscription$ = interval(1000 * 60)
      .subscribe(() => {
        if (this.shouldCheckToken()) {
          console.log('Checking if token expired at: ' + new Date());
          this.checkTokenExpired();
        }
      });
  }
  // 3/3 methods that use interval to check session end(interval freeze app)
  async checkTokenExpired() {
    this.lastCheckedTokenDate = new Date();

    let cognitoToken = await (await fetchAuthSession()).tokens;
    let expirationTimeEpoch: any = cognitoToken?.accessToken?.payload['exp'];

    let expirationTime = new Date(0);
    expirationTime.setUTCSeconds(expirationTimeEpoch);
    if (new Date() >= expirationTime) {
      console.log("Session expired. Logged out.")
      await this.signOut();
      this.router.navigate(['/']);
    }
  }

  setSessionTimeout() {
    let dateNow = new Date();
    this.appService.sessionEndTime = new Date(dateNow.getTime() + this.appService.minutesToTimeout * 60 * 1000);

    console.log("dateNow(Initial): " + dateNow)
    console.log("sessionEndTime(Initial): " + this.appService.sessionEndTime)
  }

  async checkTokenExpiredManually(event) {
    console.log(event)

    let dateNow = new Date();
    console.log("dateNow: " + dateNow)
    console.log("sessionEndTime: " + this.appService.sessionEndTime)

    if (dateNow >= this.appService.sessionEndTime) {
      console.log("Session expired. Logged out.")
      await this.signOut();

      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '50%',
        data: { message: `Your session has expired. Logging out current session`, okOnly: true }
      });

      this.router.navigate(['/']);
    }

    // Since have to detach render so image in blog doesn't keep rerender, need to detect event when click on Blogs to go back to Blogs page
    // if (this.location.path().toString().includes('blogs-back-to-list')) {

    // console.log('this.location.path().toString(): ' + this.location.path().toString())
    // console.log('this.location.path().toString().includes(blogs-detail): ' + this.location.path().toString().includes('blogs-detail'))
    // console.log('event.target.outerHTML: ' + event.target.outerHTML)
    // console.log('event.target.outerHTML.includes(header): ' + event.target.outerHTML.includes('header'))
    if (this.location.path().toString().includes('blogs-detail')
      && (
        event.target.outerHTML.includes('header') // on mobile
        || event.target.outerHTML.includes('/blogs-detail') // on desktop
      )) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        setTimeout(() => {
          this.router.navigate(['/blogs']);
        }, 1000);
      });
    }
  }


  async checkGroups() {
    this.isLandlordGroup = await this.isInGroup(ENUMS.LANDLORD_GROUP);
    this.isTenantGroup = await this.isInGroup(ENUMS.TENANT_GROUP);
  }

  checkRoute() {
    if (environment.envType !== ENUMS.HOUSEHUB_SITE) {
      if (this.hhSpecific.some(hh => this.url === hh)) {
        this.router.navigate(['/']);
      }
    } else {
      if (!this.hhSpecific.some(hh => this.url === hh)) {
        this.router.navigate(['/']);
      }
    }
  }

  async getCurrentUser(): Promise<AuthUser> {
    return await getCurrentUser();
  }

  async getCurrentSession(): Promise<AuthTokens | undefined> {
    return (await fetchAuthSession()).tokens;
  }

  async isInGroup(group: any): Promise<boolean> {
    let cognitoToken = await (await fetchAuthSession()).tokens;
    let groups: any = cognitoToken?.accessToken?.payload['cognito:groups'];

    if (groups) {
      return groups.some((g: any) => g === group);
    } else {
      return false;
    }
  }

  async currentGroups(): Promise<any> {
    let cognitoToken = await (await fetchAuthSession()).tokens;
    let groups: any = cognitoToken?.accessToken?.payload['cognito:groups'];
    return groups;
  }

  async getCurrentUserFullName(): Promise<string | undefined> {
    let cognitoToken = await (await fetchAuthSession()).tokens;
    return cognitoToken?.idToken?.payload['name']?.toString();
  }

  async getCurrentUserEmail(): Promise<string | undefined> {
    let cognitoToken = await (await fetchAuthSession()).tokens;
    return cognitoToken?.idToken?.payload['email']?.toString();
  }

  async getCurrentUserPhone(): Promise<string | undefined> {
    let cognitoToken = await (await fetchAuthSession()).tokens;
    return cognitoToken?.idToken?.payload['phone_number']?.toString();
  }

  async isSocialLoggedInUser(): Promise<boolean | undefined> {
    let cognitoToken: any = await (await fetchAuthSession()).tokens;
    let identities: any = cognitoToken?.idToken?.payload?.identities;
    if (identities && identities.length > 0) {
      if (identities[0] && identities[0]?.providerType && identities[0]?.providerType === 'Google') {
        return true;
      }
      return false;
    }
    return false;
  }

  async isCurrentUserUsingPreferredUsername(): Promise<any | undefined> {
    let cognitoToken = await (await fetchAuthSession()).tokens;
    let userSignedIn = cognitoToken?.signInDetails?.loginId?.toString();
    let userPreferred = cognitoToken?.idToken?.payload['preferred_username']?.toString();
    let userEmail = cognitoToken?.idToken?.payload['email']?.toString();
    let preferred = false;
    let preferredEmail: any = '';

    if (userPreferred) {
      preferred = userPreferred === userSignedIn;
    } else {
      preferred = userEmail === userSignedIn;
    }
    preferredEmail = userSignedIn;

    return { preferred, preferredEmail };
  }

  async getCurrentUserInitial(): Promise<string | undefined> {
    let initial = '';
    let userFullName: any = await this.getCurrentUserFullName();

    if (userFullName) {
      userFullName = userFullName.split(' ');

      initial += userFullName[0][0];
      if (userFullName.length > 1) {
        initial += userFullName[1][0];
      } else {
        initial = '(' + initial + ')';
      }
    }

    return initial;
  }

  async updateUserAttributes(accountInfo: any) {
    try {
      // const user = await getCurrentUser();
      const result = await updateUserAttributes({
        userAttributes: {
          name: accountInfo.name,
          phone_number: accountInfo.phone_number,
          // email: accountInfo.email, // it won't update yet still get email verification code
          preferred_username: accountInfo.preferred_username,
        }
      });
      console.log(result); // SUCCESS
    } catch (err) {
      console.log(err);
    }
  }

  async handleConfirmUserAttribute({
    userAttributeKey,
    confirmationCode
  }: ConfirmUserAttributeInput) {
    try {
      await confirmUserAttribute({ userAttributeKey, confirmationCode });
    } catch (error) {
      console.log(error);
    }
  }

  async updatePassword(passwords: any) {
    await updatePassword({
      oldPassword: passwords.oldPw,
      newPassword: passwords.newPw,
    });
  }

  async isAuthenticated() {
    let isAuth = false;
    let cognitoUser = this.getCurrentUser();
    if (cognitoUser != null) {
      let session = await this.getCurrentSession();
      if (session) {
        this.currentUser = cognitoUser;
        isAuth = !!session.idToken;
      }
    }

    return isAuth;
  }

  async checkIfTenantPrimaryAddress() {
    try {
      let session = await this.getCurrentSession();
      let url = this.environment.rootUrl + 'prod/tenants/';
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${session?.idToken?.toString()}`)
      return lastValueFrom(this.httpClient.get(url, { headers: this.httpHeaders, observe: 'response' }));
    }
    catch (e) {
      throw e;
      console.log(e);
    }
  }

  async signOut() {
    await signOut();
    // localStorage.removeItem('sessionEndTime');

    // this.router.navigate(['/login']);
  }
}
