export enum ENUMS {
    HEADER_TRANSITION_BACKGROUND = 'transition-background',
    HEADER_DROP_DOWN = 'drop-down',
    CONTACT_US_FULL = 'contact-us-full',
    CONTACT_US_PARTIAL = 'contact-us-partial',
    HOUSEHUB_SITE = 'house-hub-site',
    DEMO_SITE = 'demo-site',
    UNIVERSAL_REALTY_SITE = 'universal-realty-site',
    PRO_REALTY_SITE = 'pro-realty-site',
    LANDLORD_GROUP = 'LandlordGroup',
    TENANT_GROUP = 'TenantGroup',
    USER_INPUT_LIMIT_COST_SMALL = 99999,
    USER_INPUT_LIMIT_COST_LENGTH_SMALL = 5,
    USER_INPUT_LIMIT_COST_LARGE = 99999999,
    USER_INPUT_LIMIT_COST_LENGTH_LARGE = 10,
    USER_INPUT_LIMIT_EX_SHORT = 125,
    USER_INPUT_LIMIT_EX_LONG = 99999,
    USER_INPUT_LIMIT_SHORT = 255,
    USER_INPUT_LIMIT_LONG = 512,
}