import { ENUMS } from '../models/constants';

export const environment = {
    hostname: 'https://www.househubnc.com',
    domainName: null, // in ClientDomains table
    clientName: 'House Hub',
    clientAddress: '',
    clientPhone: '',
    clientEmail: 'househubnc@gmail.com',

    enableGoogleMaps: false,
    googleMapsAPIKey: 'AIzaSyDqxL3U90wzfrLT9slt53xjOmUdoEaYaPU', // Don't need since no map for HH
    googleMaps_Lat: 35.76572350077043,
    googleMaps_Lng: -78.72859163097104,

    reCaptchaSiteKey: '6Le7Z9EpAAAAAJj0F5uCkwaBuN52AuuUXmeTGZlT',

    // 'transition-background': only background and text change
    // 'drop-down': drop down and change background
    header: {
        type: ENUMS.HEADER_DROP_DOWN,
        // type: ENUMS.HEADER_TRANSITION_BACKGROUND,
    },

    facebook: 'www.facebook.com',
    instagram: 'www.instagram.com',
    twitter: 'www.twitter.com',
    linkedin: 'www.linkedin.com',
    pinterest: 'www.pinterest.com',

    rootUrl: 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/',
    contactUsUrl: 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/prod/contactUs',

    mlsR: 'MLS®',
    tmlsIdxImg: 'https://res.cloudinary.com/luxuryp/images/f_auto,q_auto/oj9yale6bkijvgworii0/screenshot_2024-01-13_at_113031_am-removebg-preview',

}