import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-message-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule
  ],
  templateUrl: './message-dialog.component.html',
  styleUrl: './message-dialog.component.scss'
})
export class MessageDialogComponent {

  delete = true;
  message = '';
  showCancel = true;

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.message) {
      this.message = data.message;
    } else {
      this.message = 'Are you sure to delete?';
    }

    if (data && data.okOnly) {
      this.showCancel = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
