import { ENUMS } from '../models/constants';

export const environment = {
    hostname: 'https://www.prorealtync.com',

    // domainName: 'ProrealtyDomainGroup', // in ClientDomains table
    // From ClientDomains table, which maps domain URL to domain name. Cognito assign domain name to user. pro-realty.ts specify localhostdomainname for local testing. This get overwritten for prod by environment.prod_pro_realty.ts.
    domainName: 'LocalhostDomainGroup',  //will be overwritten in environemnt.prod_pro_realty.ts
    domainNamRaw: 'http://localhost:4200',  //will be overwritten in environemnt.prod_pro_realty.ts

    clientName: 'PRO Realty',
    clientAddress: '5301 Denise Drive, Raleigh, NC 27606',
    clientPhone: '408-646-8817',
    clientEmail: ['prorealtync@gmail.com', 'desong.e.yu@gmail.com'],

    enableGoogleMaps: true,
    googleMapsAPIKey: 'AIzaSyCUAzkzBqiSG8mOkGpYGGjKVBhGzZTfJKo',
    googleMaps_Lat: 35.76572350077043,
    googleMaps_Lng: -78.72859163097104,

    reCaptchaSiteKey: '6LeBx1gqAAAAAPiLw9tk9WWmgV5VOcDUdMG0zEuL',

    // 'transition-background': only background and text change
    // 'drop-down': drop down and change background
    header: {
        type: ENUMS.HEADER_DROP_DOWN,
        // type: ENUMS.HEADER_TRANSITION_BACKGROUND,
    },

    facebook: 'www.facebook.com',
    instagram: 'www.instagram.com',
    twitter: 'www.twitter.com',
    linkedin: 'www.linkedin.com',
    pinterest: 'www.pinterest.com',

    rootUrl: 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/',
    contactUsUrl: 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/prod/contactUs',

    mlsR: 'MLS®',
    tmlsIdxImg: 'https://res.cloudinary.com/luxuryp/images/f_auto,q_auto/oj9yale6bkijvgworii0/screenshot_2024-01-13_at_113031_am-removebg-preview',

    // TODO: create separate bucket per client
    // bucketname: 'prorealtyworkorder',
}