<footer *ngIf="env.envType !== ENUMS.HOUSEHUB_SITE" class="head" style="background-color: rgba(0, 0, 0, 0.5);">
    <div class="header-container d-flex flex-column justify-content-center align-items-center w-100">
        <nav *ngIf="!appService.isMobile" class="nav-links">
            <ul class="d-flex align-items-end" style="margin-bottom: 0px">
                <li class="position-relative">
                    <a class="menu-title text-uppercase" [routerLink]="'/about-us'">{{ "About" | translate }}</a>
                    <!-- <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                        <li><a [routerLink]="'/about-us'">About Us</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Testimonials</a></li>
                    </ul> -->
                </li>
                <li class="position-relative">
                    <a class="menu-title text-uppercase" [routerLink]="'/search-property'">{{ "Properties" | translate
                        }}</a>
                    <ul *ngIf="false" class="submenu d-flex flex-column justify-content-center align-items-center">
                        <li><a [routerLink]="'/search-property'">Search</a></li>
                        <li><a [routerLink]="'/search-property'" [queryParams]="{ status: 'Featured' }">Featured</a>
                        </li>
                    </ul>
                </li>
                <li class="position-relative">
                    <a class="menu-title text-uppercase" [routerLink]="'/communities'">{{ "Communities" | translate
                        }}</a>
                    <ul *ngIf="false" class="submenu d-flex flex-column justify-content-center align-items-center">
                        <li *ngFor="let c of communityData"><a [routerLink]="['/community/', c.city.toLowerCase()]">{{
                                c.city
                                | translate }}</a></li>
                    </ul>
                </li>
                <li class="position-relative">
                    <a class="menu-title text-uppercase" [routerLink]="'/buyers-process'">{{ "Buyers" | translate }}</a>
                    <ul *ngIf="false" class="submenu d-flex flex-column justify-content-center align-items-center">
                        <li><a [routerLink]="'/buyers-process'">Process Roadmap</a></li>
                        <li><a [routerLink]="'/search-property'">Search Property</a>
                        </li>
                    </ul>
                </li>
                <li class="position-relative">
                    <a class="menu-title text-uppercase" [routerLink]="'/sellers-process'">{{ "Sellers" | translate
                        }}</a>
                </li>
                <li class="position-relative">
                    <a class="menu-title text-uppercase" [routerLink]="'/property-management'">{{ "Property Management"
                        | translate }}</a>
                    <ul *ngIf="false" class="submenu d-flex flex-column justify-content-center align-items-center">
                        <li><a [routerLink]="'/property-management'">Services</a></li>
                        <li><a target="_blank"
                                href="https://universalrealty.appfolio.com/oportal/users/log_in">Homeowner Portal</a>
                        </li>
                        <li><a target="_blank" href="https://universalrealty.appfolio.com/connect/users/sign_in">Tenant
                                Portal</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

        <div class="mt-5 header-text">
            <a style="color: white" routerLink="/">{{ env?.clientName }}</a>
        </div>

        <div class="d-flex justify-content-center mt-4 w-100" [class]="{'flex-column': appService.isMobile}"
            style="color: white">
            <div *ngIf="env.clientAddress" class="d-flex align-items-center col-med-4" style="margin-right: 20px">
                <fa-icon [icon]="faMapLocationDot"></fa-icon>
                <div class="ml-3">{{ env.clientAddress }}</div>
            </div>

            <div *ngIf="env.clientPhone" class="d-flex align-items-center col-med-2" style="margin-right: 20px">
                <fa-icon [icon]=" faPhone"></fa-icon>
                <div class="ml-3">{{ env.clientPhone }}</div>
            </div>

            <div *ngIf="env.clientEmail" class="d-flex align-items-center col-med-3">
                <a class="d-flex align-items-center email-wrapper" href="mailto:universalrealty.info@gmail.com">
                    <img src="assets/images/email.png" width="18px" height="16px" />
                    <div class="ml-3">{{ env.clientEmail }}</div>
                </a>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-4 w-100 align-items-center"
            [class]="{'flex-column': appService.isMobile}">

            <div>
                <a *ngIf="env.facebook" (click)="goToLink(env.facebook)"><img src="assets/images/facebook.png"
                        width="35px" height="35px" class="social-icon" /></a>
                <a *ngIf="env.instagram" (click)="goToLink(env.instagram)"><img src="assets/images/instagram.png"
                        width="35px" height="35px" class="social-icon" /></a>
                <a *ngIf="env.twitter" (click)="goToLink(env.twitter)"><img src="assets/images/twitterx.png"
                        width="35px" height="35px" class="social-icon" /></a>
                <a *ngIf="env.linkedin" (click)="goToLink(env.linkedin)"><img src="assets/images/linkedin.png"
                        width="35px" height="35px" class="social-icon" /></a>
                <a *ngIf="env.pinterest" (click)="goToLink(env.pinterest)"><img src="assets/images/pinterest.png"
                        width="35px" height="35px" class="social-icon" /></a>
            </div>

            <!-- <div>
                <span class="mr-2">
                    <fa-icon *ngIf="env.facebook" [icon]="faFacebook"></fa-icon>
                </span>
                <fa-icon *ngIf="env.instagram" [icon]="faInstagram" class="mr-2"></fa-icon>
                <fa-icon *ngIf="env.twitter" [icon]="faXTwitter" class="mr-2"></fa-icon>
                <fa-icon *ngIf="env.linkedin" [icon]="faLinkedinIn" class="mr-2"></fa-icon>
                <fa-icon *ngIf="env.pinterest" [icon]="faPinterest" class="mr-2"></fa-icon>
            </div> -->

            <!-- <share-buttons [include]="['facebook','x','linkedin','pinterest']" [show]="4" [url]="'https://x.com/'"
                [showText]="true"></share-buttons>

            <share-buttons theme="material-dark" show="11" image="https://loremflickr.com/320/240" />

            <share-button button="facebook" text="Share" showText></share-button> -->

            <!-- <button mat-fab shareButton="facebook" #facebookBtn="shareButton"
                [style.background-color]="facebookBtn.color">
                <fa-icon [icon]="facebookBtn.icon" size="lg" />
            </button>

            <button shareButton="x">
                <fa-icon [icon]="xIcon" size="lg"></fa-icon>
              </button> -->

        </div>

        <div class="mt-5 w-100 copyright d-flex justify-content-center align-items-center"
            [class]="{'flex-column': appService.isMobile}">
            <div class="copy-right-text">Copyright© 2024.</div>
            <div class="ml-2"><b>{{ env.clientName }}</b></div>
            <div class="ml-2 copy-right-text">All rights Reserved.</div>
            <span *ngIf="!appService.isMobile">&nbsp;|&nbsp;</span>
            <div class="copy-right-text"><a [routerLink]="'/privacy-policy'"
                    class="copy-right-text">Privacy&nbsp;Policy</a></div>
            <span *ngIf="!appService.isMobile">&nbsp;|&nbsp;</span>
            <div class="copy-right-text"><a [routerLink]="['/sitemap']" class="copy-right-text">Sitemap</a>
                <span *ngIf="!appService.isMobile">&nbsp;|&nbsp;</span>
            </div>
            <div class="copy-right-text">Real Estate Website Designed by <b><u><a target="_black"
                            href="http://www.househubnc.com" class="copy-right-text">House&nbsp;Hub</a></u></b></div>
        </div>
    </div>
</footer>


<footer *ngIf="env.envType === ENUMS.HOUSEHUB_SITE" class="head" style="background-color: rgba(0, 0, 0, 0.5);">

    <div *ngIf="env?.clientName" class="mt-5 header-text">
        <a style="color: white" routerLink="/" (click)="sendGAEvent('click-name')">{{ env?.clientName }}</a>
    </div>

    <div class="d-flex justify-content-center mt-4 w-100" style="color: white">
        <div *ngIf="env.clientAddress" (click)="sendGAEvent('click-address')" class="d-flex align-items-center col-4">
            <fa-icon [icon]="faMapLocationDot"></fa-icon>
            <div class="ml-3">{{ env.clientAddress }}</div>
        </div>

        <div *ngIf="env.clientPhone" (click)="sendGAEvent('click-phone')" class="d-flex align-items-center col-2 mr-5">
            <fa-icon [icon]="faPhone"></fa-icon>
            <div class="ml-3">{{ env.clientPhone }}</div>
        </div>

        <div *ngIf="env.clientEmail" class="d-flex justify-content-center col-12">
            <a (click)="sendGAEvent('click-email')" class="d-flex align-items-center email-wrapper"
                href="mailto:universalrealty.info@gmail.com">
                <img src="assets/images/email.png" width="18px" height="16px" />
                <div class="ml-3">{{ env.clientEmail }}</div>
            </a>
        </div>
    </div>

    <div class="mt-5 w-100 copyright d-flex justify-content-center align-items-center">
        <div class="copy-right-text">Copyright© 2024.</div>
        <div class="ml-2"><a (click)="sendGAEvent('click-name')" [routerLink]="'/'" class="copy-right-text"><b>{{
                    env.clientName }}</b></a></div>
        <div class="ml-2 copy-right-text">All rights Reserved.</div><span class="vertical-dash">&nbsp;|&nbsp;</span>
        <div class="copy-right-text"><a (click)="sendGAEvent('click-privacy')" [routerLink]="['/privacy-policy']"
                class="copy-right-text">Privacy&nbsp;Policy</a>
        </div><span class="vertical-dash">&nbsp;|&nbsp;</span>
        <div class="copy-right-text"><a (click)="sendGAEvent('click-terms')" [routerLink]="['/terms-of-service']"
                class="copy-right-text">Terms&nbsp;Of&nbsp;Service</a></div>
        <span *ngIf="!appService.isMobile">&nbsp;|&nbsp;</span>
        <div class="copy-right-text"><a (click)="sendGAEvent('click-sitemap')" [routerLink]="['/sitemap']"
                class="copy-right-text">Sitemap</a></div>
    </div>

</footer>