import { Injectable, OnInit } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import emailjs from '@emailjs/browser';
import { environment } from '../../env/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService implements OnInit {
  private api = "";

  env = environment;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Access-Control-Allow-Methods', 'OPTIONS,POST')
    // .set("Access-Control-Allow-Headers", 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token')
    // .set('Authorization', `Bearer ${environment.mlsBearToken}`)
    .set('Content-Type', `application/json`);

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    // emailjs.init({
    //   publicKey: environment.email.publicKey,
    //   blockHeadless: true,
    //   blockList: {
    //     // list: ['foo@emailjs.com', 'bar@emailjs.com'],
    //   },
    //   limitRate: {
    //     throttle: 10000, // 10s
    //   },
    // });
  }

  // sendMail(data: any) {
  //   return this.http.post(this.api, data, { responseType: 'text' }).pipe(
  //     map((response: any) => {
  //       if (response) return response;
  //     },
  //       (error: any) => error
  //     )
  //   )
  // }

  // sendMail(data: any) {
  //   emailjs.send(environment.email.serviceId, environment.email.templateId, data, {
  //     publicKey: environment.email.publicKey,
  //   })
  //     .then(
  //       (response) => {
  //         console.log('SUCCESS!', response.status, response.text);
  //       },
  //       (err) => {
  //         console.log('FAILED...', err);
  //       },
  //     );
  // }

  sendMail(contactUsData: any) {
    let body = { contactUsData: contactUsData };
    let api = this.env.contactUsUrl;
    return new Promise((resolve, _) => {
      this.http.post(api, body, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          resolve(data);
        }
      )
    })
  }

}
