// import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ENUMS } from '../../models/constants';
import { ContactUsService } from '../services/contact-us.service';

import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { GoogleMapsModule, MapMarker } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';

import { environment } from '../../env/environment';

import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_LOADER_OPTIONS,
  RecaptchaLoaderOptions,
} from "ng-recaptcha";

import { AppServiceService } from '../services/app-service.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    forwardRef(() => MyTelInput),
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    GoogleMapsModule,
    TranslateModule,
    RecaptchaModule,
    RouterModule,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCaptchaSiteKey } as RecaptchaSettings,
    },
  ],
})
export class ContactUsComponent implements OnInit {
  @Input() public showMap: any = false;

  pageType = null;
  enums: any = ENUMS;
  contactUsFormGroup: FormGroup;
  submitted = false;
  valid = false;
  email = new FormControl('', [Validators.required, Validators.email]);

  mapOptions: google.maps.MapOptions = {
    center: { lat: environment.googleMaps_Lat, lng: environment.googleMaps_Lng },
    zoom: 16
  }
  marker = {
    position: { lat: environment.googleMaps_Lat, lng: environment.googleMaps_Lng },
  }

  environment = environment;

  recaptchaValid = false;
  userConsented = false;
  subscribeConsent = true;

  subscribersUrl;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    .set('Content-Type', `application/json`);

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private contactUsService: ContactUsService,
    public appService: AppServiceService,
    private analyticsService: GoogleAnalyticsService,
    private httpClient: HttpClient,
  ) {
    if (this.router.url === 'contact-us') {
      // No such route currently
      this.pageType = this.enums.CONTACT_US_FULL;
    } else {
      this.pageType = this.enums.CONTACT_US_PARTIAL;
    }

    this.contactUsFormGroup = this.builder.group({
      firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      // email: ['', [Validators.required, Validators.email]],
      phone: ['', [
        Validators.required,
        // Validators.pattern("^[0-9]*$"),
        // Validators.pattern('^\d{3}-\d{3}-\d{4}$'),
        // Validators.minLength(10),
        // Validators.maxLength(13)
      ]],
      message: ['', [Validators.required]],
    })

    this.subscribersUrl = `${environment.rootUrl}prod/subscribers/`;
  }

  ngOnInit() {

  }

  resolved(captchaResponse: any) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.recaptchaValid = true;
  }

  onError() {
    console.warn(`reCAPTCHA error encountered`);
  }

  getNameErrorMessage() {
    if (this.contactUsFormGroup.controls['firstName'].invalid
      || this.contactUsFormGroup.controls['lastName'].invalid) {
      return 'Please enter only letters.'
    }
    return '';
  }


  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value.';
    }

    return this.email.hasError('email') ? 'Please enter a valid email.' : '';
  }

  getPhoneErrorMessage() {
    // if (this.contactUsFormGroup.controls['phone'].invalid) {
    return 'Please enter phone number in format: xxx-xxx-xxxx.';
    // }
    // return '';
  }

  getMessageErrorMessage() {
    if (this.contactUsFormGroup.controls['message'].invalid) {
      return 'Please enter a message so we could better help.'
    }
    return '';
  }


  keyPress(event: any) {
    // const pattern = /[0-9\+\-\ ]/;
    // let inputChar = String.fromCharCode(event.charCode);
    // if (event.keyCode != 8 && !pattern.test(inputChar)) {
    //   event.preventDefault();
    // }
    const regex = new RegExp(/^\d{3}-\d{3}-\d{4}$/);
    let res = event.target.value.match(regex);
    if (res && res.length > 0) {
      console.log(res)
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  sendEmail(data: any) {
    // console.log(this.contactUsFormGroup.value)

    let currentPage = this.router.url.slice(1);
    this.analyticsService.trackEvent(`contact-us-fr-${currentPage}`, "", "user-action");

    let newMessage = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: `${data.phone.area}-${data.phone.exchange}-${data.phone.subscriber}`,
      email: `${this.email.value}`,
      notes: data.message,
    }

    this.contactUsService.sendMail(newMessage).then(
      (data: any) => {
        console.log(data);
      }
    ).catch((error) => console.log(error));

    if (this.subscribeConsent) {
      // User don't login on contact us so no session
      // Auto send confirmation to subscribe email if NOT confirmed
      // this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.post(this.subscribersUrl, newMessage, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          console.log(data)
        }
      )
    }

    // this.contactUsService.sendMail(newMessage);

    this.submitted = true;
  }

  updatePrivacyConsent(event: any) {
    if (event && event.target) {
      this.userConsented = event.target.checked;
    }
  }

  updateSubscribeConsent(event: any) {
    if (event && event.target) {
      this.subscribeConsent = event.target.checked;
    }
  }
}

/** Data structure for holding telephone number. */
export class MyTel {
  constructor(
    public area: string,
    public exchange: string,
    public subscriber: string,
  ) { }
}

/** Custom `MatFormFieldControl` for telephone number input. */
@Component({
  selector: 'example-tel-input',
  templateUrl: './example-tel-input-example.html',
  styleUrl: './example-tel-input-example.css',
  providers: [{ provide: MatFormFieldControl, useExisting: MyTelInput }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class MyTelInput implements ControlValueAccessor, MatFormFieldControl<MyTel>, OnDestroy {
  static nextId = 0;
  @ViewChild('area') areaInput: any;
  @ViewChild('exchange') exchangeInput: any;
  @ViewChild('subscriber') subscriberInput: any;

  parts: FormGroup<{
    area: FormControl<string | null>;
    exchange: FormControl<string | null>;
    subscriber: FormControl<string | null>;
  }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'example-tel-input';
  id = `example-tel-input-${MyTelInput.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return !area && !exchange && !subscriber;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): MyTel | null {
    if (this.parts.valid) {
      const {
        value: { area, exchange, subscriber },
      } = this.parts;
      return new MyTel(area!, exchange!, subscriber!);
    }
    return null;
  }
  set value(tel: MyTel | null) {
    const { area, exchange, subscriber } = tel || new MyTel('', '', '');
    this.parts.setValue({ area, exchange, subscriber });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.userAriaDescribedBy = '';
    this._placeholder = '';

    this.parts = formBuilder.group({
      area: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern("^[0-9]*$")]],
      exchange: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern("^[0-9]*$")]],
      subscriber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]*$")]],
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.example-tel-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.subscriber.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.exchange.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.area.valid) {
      this._focusMonitor.focusVia(this.exchangeInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.areaInput, 'program');
    }
  }

  writeValue(tel: MyTel | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}