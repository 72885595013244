import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMapLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';

// import { bootstrapApplication } from '@angular/platform-browser';
// import {
//   provideShareButtonsOptions,
//   SharerMethods,
//   withConfig,
// } from 'ngx-sharebuttons';

import { ShareButton } from 'ngx-sharebuttons/button';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { shareIcons } from 'ngx-sharebuttons/icons';

import { ShareButtonDirective } from 'ngx-sharebuttons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faXTwitter, faLinkedinIn, faPinterest } from '@fortawesome/free-brands-svg-icons';

import { ENUMS } from '../../models/constants';
import { environment } from '../../env/environment';
import { communityData } from "../communities/communities-data";
import { AppServiceService } from '../services/app-service.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule,

    ShareButton,
    ShareButtons,
    ShareButtonDirective, FaIconComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})

export class FooterComponent implements OnInit {
  faMapLocationDot = faMapLocationDot;
  faPhone = faPhone;

  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faXTwitter = faXTwitter;
  faLinkedinIn = faLinkedinIn;
  faPinterest = faPinterest;

  env: any = environment;
  communityData = communityData;
  ENUMS = ENUMS;

  constructor(
    public appService: AppServiceService,
    private analyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {

  }

  ngOnInit() {

  }

  sendGAEvent(type: string) {
    this.analyticsService.trackEvent(type, "", "user-action");
  }

  goToLink(url: string) {
    // const urlSerialized = this.router.serializeUrl(
    //   this.router.createUrlTree([url])
    // );

    // window.open(urlSerialized, "_blank");

    window.open('//' + url, "_blank");
  }
}

// bootstrapApplication(FooterComponent, {
//   providers: [
//     provideShareButtonsOptions(
//       shareIcons(),
//       withConfig({
//         debug: true,
//         sharerMethod: SharerMethods.Anchor,
//       })
//     ),
//   ],
// });
