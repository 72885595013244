<div *ngIf="pageType == enums.CONTACT_US_PARTIAL" class="pt-5 pb-5 contact-us-outter-wrapper">
    <div *ngIf="!submitted" class="container position-relative">
        <h2 class="text-center mb-2 contact-us-submitted text-uppercase" style="font-size: 2rem">{{ "Contact Our Team" |
            translate}}
        </h2>
        <!-- <div class="d-flex justify-content-center position-relative"> -->
        <span *ngIf="!appService.isMobile" class="dash-contact"></span>
        <h3 class="text-center mb-4 contact-us-submitted text-uppercase" style="font-size: 3rem">{{ "Get In Touch" |
            translate }}</h3>
        <span *ngIf="!appService.isMobile" class="dash-contact-2"></span>

        <!-- </div> -->

        <form [formGroup]="contactUsFormGroup" (submit)="sendEmail(contactUsFormGroup.value)">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>{{ "First name" | translate }} </mat-label>
                            <input matInput placeholder="" formControlName="firstName" required>
                            <mat-hint>{{ "First name" | translate }}</mat-hint>
                            <!-- <mat-error
                                        *ngIf="contactUsFormGroup.controls['firstName'].invalid
                                        && (contactUsFormGroup.controls['firstName'].dirty || contactUsFormGroup.controls['firstName'].touched)">{{getErrorMessage()}}</mat-error> -->
                            <mat-error
                                *ngIf="contactUsFormGroup.controls['firstName'].invalid">{{getNameErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4" style="margin-left: 3%">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>{{ "Last name" | translate }} </mat-label>
                            <input matInput placeholder="" formControlName="lastName" required>
                            <mat-hint>{{ "Last name" | translate }} </mat-hint>
                            <mat-error
                                *ngIf="contactUsFormGroup.controls['lastName'].invalid">{{getNameErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row justify-content-center mt-3">
                    <div class="col-md-4">
                        <div class="example-container">
                            <mat-form-field class="w-100">
                                <mat-label>{{ "Enter your email" | translate }}</mat-label>
                                <input matInput placeholder="email@example.com" [formControl]="email" required>
                                <mat-hint>{{ "Email" | translate }}</mat-hint>
                                @if (email.invalid) {
                                <mat-error>{{getEmailErrorMessage()}}</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4" style="margin-left: 3%">
                        <mat-form-field class="w-100">
                            <mat-label>{{ "Phone number" | translate }}</mat-label>
                            <example-tel-input formControlName="phone" required></example-tel-input>
                            <!-- <mat-icon matSuffix>{{ "Phone" | translate }}</mat-icon> -->
                            <mat-hint>{{ "Include area code" | translate }}</mat-hint>
                            <!-- @if (contactUsFormGroup.invalid || ) { -->
                            <mat-error
                                *ngIf="contactUsFormGroup.controls['phone'].errors">{{getPhoneErrorMessage()}}</mat-error>
                            <!-- } -->
                        </mat-form-field>
                    </div>
                </div>

                <div class="row justify-content-center mt-3">
                    <div class="col-md-9" style="margin-left: 5.5%">
                        <mat-form-field style="width: 93%" appearance="fill">
                            <mat-label>{{ "Message" | translate }}</mat-label>
                            <textarea matInput placeholder="" formControlName="message" required></textarea>
                            <mat-hint>{{ "Message" | translate }}</mat-hint>
                            <!-- <mat-error
                                        *ngIf="contactUsFormGroup.controls['firstName'].invalid
                                        && (contactUsFormGroup.controls['firstName'].dirty || contactUsFormGroup.controls['firstName'].touched)">{{getErrorMessage()}}</mat-error> -->
                            <mat-error
                                *ngIf="contactUsFormGroup.controls['message'].invalid">{{getMessageErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row justify-content-center mt-3">
                    <div class="col-md-9" style="margin-left: 5.5%">
                        <div class="d-flex consent-wrapper">
                            <input type="checkbox" (change)="updateSubscribeConsent($event)"
                                [checked]="subscribeConsent" />
                            <p class="ml-2">
                                Subcribe to our newsletter for latest news, blogs, promotions, and insights for
                                buyers/sellers.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mt-1">
                    <div class="col-md-9" style="margin-left: 5.5%">
                        <div class="d-flex consent-wrapper">
                            <input type="checkbox" (change)="updatePrivacyConsent($event)" [checked]="userConsented" />
                            <p class="ml-2">
                                I agree by providing {{environment.clientName}} your contact information, you
                                acknowledge and
                                agree to our
                                <a [routerLink]="'/privacy-policy'">Privacy Policy</a>. This consent isn't necessary for
                                purchasing
                                any services or products. You may opt out at any time.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mt-3 mb-3">
                    <div class="d-flex justify-content-center">
                        <re-captcha (resolved)="resolved($event)" (errored)="onError()"
                            errorMode="handled"></re-captcha>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <button mat-flat-button type="submit"
                        class="d-flex justify-content-center button-default text-uppercase"
                        [disabled]="email.invalid">{{ "Submit(No Recaptcha)" |
                        translate }}</button>
                    <button mat-flat-button type="submit"
                        class="d-flex justify-content-center button-default text-uppercase"
                        [disabled]="!contactUsFormGroup.valid || email.invalid || !recaptchaValid">{{ "Submit" |
                        translate }}</button>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="submitted" class="container">
        <div class="row justify-content-center">
            <div class="col-md-5 align-items-center w-75">
                <div class="row justify-content-center contact-us-submitted">
                    <div class="offset-md-2">Thank you for contacting us.</div>
                    <div class="offset-md-3" style="font-size: 1.6rem">Someone will reachout to you shortly. We look
                        forwarding to working with you!</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showMap" class="my-google-map mt-5">
        <google-map width="100%" height="50vh" [options]="mapOptions">
            <map-marker [position]="marker.position"></map-marker>
        </google-map>
    </div>
</div>